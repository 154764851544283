.flexbox-container {
  display: flex;
  flex-direction: row;
}

.App {
  text-align: left;
}

.btns {
  color: #61dafb;
}

.btns:hover{
  color: hotpink;
  cursor:pointer;
}

.panel {
  position:sticky;
  top: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: top;
}

.dtable {
  position: sticky;
  top: 80px;
  min-width: 40%;
  padding-top: 10px;
  padding-left: 25px;
  padding-right: 10px;

}

.tickerSearch{
  font-size: 30px;
  color: white;
  border: '1px solid #FFFFFF';
  padding: 10px;
  align-content: center;
  vertical-align: middle;
}

.inputBox{
  width: 300px;
  font-size: 30px;
  background-color: #444444;
  color: white;
  outline: slategray;
  border: none;
}

.tweetsEmbed{
  margin:4px, 4px;
  padding:4px;
  background-color: black;
  width: 500px;
  height: 1500px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 25px;
}

.tweets_header{
  color: white;
  border: '1px solid #FFFFFF',
}

.App-header {
  background-color: #000000;
  min-height: 10vh;
  display: inline;
  font-size: calc(10px + 2vmin);
  color: white;
}

.runtime {
  text-align: right;
  vertical-align: right;
  align-items: right;
  align-content: right;
  text-align: right;
  display: table-cell;
  display: inline;
}
