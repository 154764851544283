.header {
    padding: 10px;
    display: flex;
    border-bottom: 0.1px solid gray;
    background-color: black;
    position: sticky;
    top: 0;
    z-index: 999;
}

.header__left {
    display: flex;
}

.header__left > img {
    object-fit: contain;
    height: 50px;
    margin-right: 10px;
}

.header__right > img {
    padding-left: 10px ;
    object-fit: contain;
    height: 50px;
    margin-right: 10px;
    overflow: 'hidden';
    border-radius: 10;
}

.header__right > img:hover {
    transform:scale(1.1, 1.1);
    cursor:pointer;
}

.header__right {
    display: flex;
    height: 50px;
    
}

.support {
    color: #61dafb;
    text-decoration:none;
    display: table-cell;
    vertical-align: middle;
    padding-top: 12px;
}

.support:hover {
    color: hotpink;
    cursor:pointer;
  }
  